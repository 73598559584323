import React from 'react';
import Layout from "../../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class VariousGojuRyuNewspaperClippings extends React.Component {
    render() {
        return (
            <Layout>
                <h1>1987, 1988 - Various Goju Ryu newspaper clippings</h1>
                <StaticImage src="../../../images/variousclippings/ResizedImage600872-9035807.jpg"
                             alt="Karate at school"/>
                <StaticImage src="../../../images/variousclippings/ResizedImage600965-2605782.jpg"
                             alt="Final test for girls"/>
                <StaticImage src="../../../images/variousclippings/ResizedImage600874-818562.jpg"
                             alt="Karate clean-up"/>
            </Layout>);
    }
}

export default VariousGojuRyuNewspaperClippings;
